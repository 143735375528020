import {
  Box,
  Heading,
  Stack,
  Container,
  useBreakpointValue,
  Spinner,
  Flex,
} from '@chakra-ui/react'
import { SignInForm } from '@features/auth/components/SignInForm'
import { Logo } from '@components/Elements/Logo'
import { PageLoader } from '@components/Loader/PageLoader'
import { withUser } from '@lib/firebase/withUser'
import { AuthAction } from '@lib/firebase/AuthAction'
import { useUser } from '@lib/firebase/provider'
import { setCookie } from '@lib/firebase/cookies'
import { core, getTenanancyCookieName } from '@lib/firebase/config'
import { TENANT } from '@lib/firebase/types'

const SignIn = () => {
  const { loading } = useUser()
  const breakPointValue = useBreakpointValue({ base: 'xs', md: 'sm' })

  if (loading) {
    return <PageLoader />
  }

  return (
    <Container maxW="md" py={{ base: '12', md: '24' }}>
      {loading ? (
        <Flex
          w={'100%'}
          justify="center"
          h={'100%'}
          align={'center'}
          direction={'column'}
        >
          <Spinner />
        </Flex>
      ) : (
        <Stack spacing="8">
          <Stack spacing="6">
            <Box px="120" py="50" marginTop="30px">
              <Logo />
            </Box>
            <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
              <Heading size={breakPointValue}>Log in to your account</Heading>
              {/* <HStack spacing="1" justify="center"> */}
              {/*  <Text color="muted">Don't have an account?</Text> */}
              {/*  <Link href="/register"> */}
              {/*    <Button variant="link" colorScheme="blue"> */}
              {/*      Sign up */}
              {/*    </Button> */}
              {/*  </Link> */}
              {/* </HStack> */}
            </Stack>
          </Stack>
          <Stack spacing="6">
            <SignInForm />
          </Stack>
        </Stack>
      )}
    </Container>
  )
}

export async function getServerSideProps({ req, res }) {
  setCookie(getTenanancyCookieName(), TENANT.user, { req, res }, { path: '/' })
  return {
    props: {},
  }
}

export default withUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenUnauthedAfterInit: AuthAction.RENDER,
  whenAuthedBeforeRedirect: AuthAction.SHOW_LOADER,
  LoaderComponent: PageLoader,
  appPageUrl: '/',
  authPageURL: core.authPageUrl,
  forceRefreshToken: true,
})(SignIn)
